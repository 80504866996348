$backgroundColor: #ffffff;
$bodyColor: #000000;
$sans: "Gudea", san-serif, -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
$ser: "Lora", serif;

*:focus {
  outline: none;
}

body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: $sans;
}

* {
  font-family: $sans;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $sans;
}

* {
  border-radius: 0!important;
}

#site-title {
  border: 4px solid;
  border-left: 0px;
}

#legend {

}

a {
  color: rgba(141,162,251);
  &:hover {
  color: #2972fa;

    text-decoration: 2px wavy inherit;
  }
}

#blog a {
  color: rgba(224,36,36,var(--color-opacity));
  &:hover {
    color: rgba(224,36,36, .7);
  }
}

time {
  color: lightslategray;
}

.card {
  box-shadow: none;
  &:hover {
    box-shadow: 0 5px 12px 0 rgba(253,232,232, .82),0 0 5px rgb(253, 232, 232);

    .card__image {
      border-bottom: 2px dotted;
      background-blend-mode: normal;
    }
    
    p.tile__subtitle {
      border-top: 2px dotted;
    }
  }

  p {
    
    margin: .6rem 0;
    padding-top: .3rem;
    }

  details.accordion {
    padding-top: 0px;
    padding-bottom: 0px;
    p {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
  .card__image::after {
  background-color: rgba(0, 0, 0, 0);
  }

  p.tile__subtitle {
    border-top: 2px dotted white;
    border-radius: 4px;
    i {
      float: right;
      margin: .6rem;
    }
  }
  .card__image {
    border-bottom: 2px dotted white;
    border-radius: 4px;
    background-blend-mode: luminosity;
    &:hover {
      background-blend-mode: normal;
    }
  }
}


.footnotes {
  margin-top: 9rem;
  padding-top: 2rem;
  border-top: 1px dotted whitesmoke;
  font-size: small;
  p {
    font-size: inherit;
  }
}

iframe {
  border: 0px;
}